<template>
  <VueDraggable
    :list="items"
    handle=".handle"
    @end="onDragEnd"
    @start="onDragStart"
  >
    <RollUpRuleConditionsListItem
      v-for="(item, index) in items"
      :key="index"
      :index="index"
      :item="item"
      class="mb-1 cursor-pointer mc-card"
      @click.native="$emit('edit', index)"
      @delete="$emit('delete', index)"
    />
  </VueDraggable>
</template>

<script>
import VueDraggable from 'vuedraggable'
import RollUpRuleConditionsListItem from './RollUpRuleConditionsListItem'

export default {
  components: {
    VueDraggable,
    RollUpRuleConditionsListItem,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onDragStart(e) {
      e.target.classList.add('is-grabbing')
    },
    onDragEnd(e) {
      e.target.classList.remove('is-grabbing')
    },
  },
}
</script>

<style scoped>
.mc-card:hover {
  background-color: var(--v-ap-light-grey-base);
}
</style>

<style>
.handle {
  cursor: grab;
}

.is-grabbing * {
  cursor: grabbing !important;
}
</style>

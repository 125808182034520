<template>
  <ApDialog
    :value="true"
    title="Roll Up Rule"
    max-width="960"
    :actions="actions"
    @cancel="$emit('cancel')"
    @save="onSave"
  >
    <template #content>
      <div class="px-6 py-3 ap-black--text">
        <v-form v-if="rollUpRuleMetadata" ref="form">
          <v-row>
            <v-col>
              <div class="font-weight-medium text-body-2">
                What final record do you want to update? *
              </div>
              <div class="d-flex">
                <v-radio-group
                  v-model="finalRecordEntity"
                  row
                  class="ma-0"
                  hide-details
                  @change="resetFieldsToRollUp"
                >
                  <v-radio
                    v-for="item in rollUpRuleMetadata.entities"
                    :key="item.name"
                    :label="item.label"
                    :value="item.name"
                    hide-details
                    class="ma-0 mr-3"
                  />
                </v-radio-group>
              </div>
            </v-col>
          </v-row>

          <v-divider class="my-3" />

          <v-row>
            <v-col>
              <div class="font-weight-medium text-body-2">
                Where do you want to look for values? *
              </div>
              <div class="d-flex">
                <v-checkbox
                  v-for="item in rollUpRuleMetadata.entities"
                  :key="item.name"
                  v-model="lookUpEntities"
                  :label="item.label"
                  :value="item.name"
                  :rules="mandatoryArrayRule"
                  hide-details
                  class="ma-0 mr-3"
                />
              </div>
            </v-col>
          </v-row>

          <v-divider class="my-3" />

          <div
            class="font-weight-medium text-body-2 mt-5 mb-2 d-flex align-center"
          >
            When do you want this rule to apply?
            <span
              v-if="whenByColumn.length === 0 && whenByRecord.length === 0"
              class="d-flex align-center ap-blue--text text-caption ml-2"
            >
              <v-icon small color="ap-blue" class="mr-1">
                $mdi-information-outline
              </v-icon>
              The rule will always run if no condition provided.
            </span>
          </div>

          <div
            class="font-weight-medium text-caption mt-5 mb-2 d-flex align-center"
          >
            When record's fields match the following conditions:
          </div>

          <template v-if="whenByColumn.length > 0">
            <div
              v-for="(whenCondition, index) in whenByColumn"
              :key="`when_column_${index}`"
              class="d-flex mb-1"
            >
              <v-row dense>
                <v-col cols="4">
                  <v-autocomplete
                    v-model="whenCondition.field"
                    :items="rollUpRuleMetadata.whenToApply.column"
                    :rules="mandatoryRule"
                    label="Field"
                    item-value="fieldName"
                    item-text="fieldLabel"
                    outlined
                    dense
                    hide-details
                    return-object
                    @change="fieldChanged(whenByColumn, index)"
                  />
                </v-col>

                <v-col cols="4">
                  <v-select
                    v-if="whenCondition.field"
                    v-model="whenCondition.operator"
                    :items="
                      whenCondition.field ? whenCondition.field.operators : []
                    "
                    :rules="mandatoryRule"
                    label="Operator"
                    item-value="name"
                    item-text="label"
                    outlined
                    dense
                    hide-details
                    return-object
                    @change="operatorChanged(whenByColumn, index)"
                  />
                </v-col>

                <v-col cols="4">
                  <v-select
                    v-if="
                      whenCondition.operator &&
                      whenCondition.operator.type === 'list'
                    "
                    v-model="whenCondition.value"
                    :items="
                      whenCondition.operator
                        ? whenCondition.operator.values
                        : []
                    "
                    :rules="mandatoryRule"
                    :label="whenCondition.operator.valuesLabel"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
              </v-row>

              <div class="d-flex align-center justify-center pl-3">
                <v-icon @click="removeWhenByColumn(index)">$mdi-delete</v-icon>
              </div>
            </div>
          </template>

          <v-btn outlined x-small @click="addWhenByColumn">
            <v-icon left small>$mdi-plus</v-icon>Add
          </v-btn>

          <div class="font-weight-medium text-caption mt-5 d-flex align-center">
            When some record in the set match all the following conditions:
          </div>

          <template v-if="whenByRecord.length > 0">
            <v-checkbox
              v-model="isPrimary"
              color="primary"
              class="mt-1 mb-3 pt-0"
              hide-details
              dense
            >
              <template #label>
                <span class="text-caption" style="margin-left: -6px">
                  Is Primary record
                </span>
              </template>
            </v-checkbox>

            <div
              v-for="(whenCondition, index) in whenByRecord"
              :key="`when_record_${index}`"
              class="d-flex mb-1"
            >
              <v-row dense>
                <v-col cols="4">
                  <v-autocomplete
                    v-model="whenCondition.field"
                    :items="rollUpRuleMetadata.whenToApply.record"
                    :rules="mandatoryRule"
                    label="Field"
                    item-value="fieldName"
                    item-text="fieldLabel"
                    outlined
                    dense
                    hide-details
                    return-object
                    @change="fieldChanged(whenByRecord, index)"
                  />
                </v-col>

                <v-col cols="4">
                  <v-select
                    v-if="whenCondition.field"
                    v-model="whenCondition.operator"
                    :items="
                      whenCondition.field ? whenCondition.field.operators : []
                    "
                    :rules="mandatoryRule"
                    label="Operator"
                    item-value="name"
                    item-text="label"
                    outlined
                    dense
                    hide-details
                    return-object
                    @change="operatorChanged(whenByRecord, index)"
                  />
                </v-col>

                <v-col cols="4">
                  <template v-if="whenCondition.operator">
                    <v-select
                      v-if="whenCondition.operator.type === 'boolean'"
                      v-model="whenCondition.value"
                      :items="[true, false]"
                      :rules="mandatoryRule"
                      label="Value"
                      outlined
                      dense
                      hide-details
                    />

                    <v-text-field
                      v-if="whenCondition.operator.type === 'text'"
                      dense
                      outlined
                      label="Value"
                      hide-details
                    />
                  </template>
                </v-col>
              </v-row>
              <div
                v-if="
                  (whenByRecord.length > 1 && isWhenByRecordMandatory) ||
                  !isWhenByRecordMandatory
                "
                class="d-flex align-center justify-center pl-3"
              >
                <v-icon @click="removeWhenByRecord(index)">$mdi-delete</v-icon>
              </div>
            </div>
          </template>

          <v-btn outlined x-small @click="addWhenByRecord">
            <v-icon left small>$mdi-plus</v-icon>Add
          </v-btn>

          <v-divider class="my-3" />

          <div
            class="font-weight-medium text-body-2 mt-5 mb-2 d-flex align-center"
          >
            Which fields from final record do you want to update? *
          </div>

          <div
            v-for="(fieldConfigs, index) in fieldsToRollUp"
            :key="index"
            class="mb-3"
          >
            <div class="d-flex">
              <v-row dense>
                <v-col cols="4">
                  <v-autocomplete
                    v-model="fieldConfigs.field"
                    :items="
                      finalRecordEntity
                        ? rollUpRuleMetadata.rollUpFieldsPerEntity[
                            finalRecordEntity
                          ]
                        : []
                    "
                    :rules="mandatoryRule"
                    label="Field"
                    item-value="name"
                    item-text="label"
                    outlined
                    dense
                    hide-details
                    return-object
                    @change="fieldChanged(fieldsToRollUp, index)"
                  />
                </v-col>

                <v-col cols="4">
                  <v-select
                    v-if="fieldConfigs.field"
                    v-model="fieldConfigs.operator"
                    :items="
                      fieldConfigs.field ? fieldConfigs.field.operators : []
                    "
                    :rules="mandatoryRule"
                    label="Operator"
                    item-value="name"
                    item-text="label"
                    outlined
                    dense
                    hide-details
                    return-object
                    @change="operatorChanged(fieldsToRollUp, index)"
                  >
                    <template #item="{ item }">
                      <div
                        class="py-1"
                        style="font-size: 0.8125rem; line-height: 1rem"
                      >
                        <div class="font-weight-medium">
                          {{ item.label }}
                        </div>
                        <div
                          v-if="
                            ['SetValue', 'SetValueFromRecord'].includes(
                              item.name
                            )
                          "
                          class="text-caption"
                          style="margin-top: -4px"
                        >
                          Requirement: Match record Condition
                        </div>
                      </div>
                    </template>
                  </v-select>
                </v-col>

                <template
                  v-if="
                    fieldConfigs.operator &&
                    fieldConfigs.operator.name === 'SetValue'
                  "
                >
                  <v-col v-if="fieldConfigs.field.type === 'string'" cols="4">
                    <v-text-field
                      v-model="fieldConfigs.value"
                      dense
                      outlined
                      label="Value"
                      hide-details
                      :rules="mandatoryRule"
                    />
                  </v-col>
                </template>

                <v-col
                  v-else-if="
                    fieldConfigs.operator &&
                    fieldConfigs.operator.type === 'list'
                  "
                  cols="4"
                >
                  <v-autocomplete
                    v-model="fieldConfigs.value"
                    :items="
                      whenByRecord.length === 0 &&
                      fieldConfigs.operator.name === 'SetValueFromRecord'
                        ? []
                        : fieldConfigs.operator.values
                    "
                    :rules="mandatoryRule"
                    :label="fieldConfigs.operator.valuesLabel"
                    item-value="name"
                    item-text="label"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>

                <v-col
                  v-else-if="
                    fieldConfigs.operator &&
                    fieldConfigs.operator.type === 'list_editor'
                  "
                  cols="4"
                >
                  <v-combobox
                    v-model="fieldConfigs.value"
                    outlined
                    dense
                    :items="[]"
                    :rules="mandatoryRule"
                    hide-selected
                    label="Order by Data tags"
                    multiple
                    small-chips
                    hide-details
                  >
                    <template #no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Type and press enter to create data tag
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>

              <div
                v-if="fieldsToRollUp.length > 1"
                class="d-flex align-center justify-center pl-3"
              >
                <v-icon @click="removeFieldToRollUp(index)">$mdi-delete</v-icon>
              </div>
            </div>

            <div class="d-flex align-center">
              <v-checkbox
                v-model="fieldConfigs.overridePrimary"
                color="primary"
                class="mt-0 pt-0"
                hide-details
                dense
              >
                <template #label>
                  <span class="text-caption" style="margin-left: -6px"
                    >Override Primary</span
                  >
                </template>
              </v-checkbox>
            </div>
          </div>

          <v-btn outlined x-small @click="addFieldToRollUp">
            <v-icon left small>$mdi-plus</v-icon>Add
          </v-btn>
        </v-form>
      </div>
    </template>
  </ApDialog>
</template>

<script>
import ApDialog from '@/components/common/ApDialog'
import { mapState } from 'vuex'
import Vue from 'vue'

export default {
  components: {
    ApDialog,
  },
  props: {
    rule: {
      type: Object,
      default: () => {
        return {
          finalRecordEntity: null,
          lookUpEntities: [],
          whenByColumn: [],
          whenByRecord: [],
          fieldsToRollUp: [],
        }
      },
    },
  },
  data() {
    return {
      mandatoryRule: [(v) => (v !== undefined && v !== null) || ''],
      mandatoryArrayRule: [(v) => (v && v.length > 0) || ''],

      // dialog helpers
      isDeleteWhenRecordWarningDialogVisible: false,

      // form
      finalRecordEntity: null,
      lookUpEntities: [],
      whenByColumn: [],
      whenByRecord: [],
      fieldsToRollUp: [],
      isPrimary: false,
    }
  },
  computed: {
    ...mapState('business-rules', {
      rollUpRuleMetadata: (state) => state.rulesMetadata.RollUp,
    }),
    actions() {
      var actions = [
        {
          event: 'cancel',
          label: 'Cancel',
          color: 'primary',
          outlined: true,
        },
        {
          event: 'save',
          label: 'Done',
          color: 'primary',
        },
      ]

      return actions
    },
    isWhenByRecordMandatory() {
      return this.fieldsToRollUp.some((el) =>
        ['SetValue', 'SetValueFromRecord'].includes(el.operator?.name)
      )
    },
  },
  created() {
    if (this.rule.finalRecordEntity) {
      this.finalRecordEntity = this.rule.finalRecordEntity
      this.lookUpEntities = this.rule.lookUpEntities

      if (this.rule.whenByColumn) {
        this.whenByColumn = this.rule.whenByColumn.map((condition) => {
          return {
            field:
              this.rollUpRuleMetadata.whenToApplyObj.column[condition.field],
            operator:
              this.rollUpRuleMetadata.whenToApplyObj.column[condition.field]
                .operatorsObj[condition.operator],
            value: condition.value,
          }
        })
      }

      if (this.rule.whenByRecord.conditions) {
        this.whenByRecord = this.rule.whenByRecord.conditions.map(
          (condition) => {
            const a = {
              field:
                this.rollUpRuleMetadata.whenToApplyObj.record[condition.field],
              operator:
                this.rollUpRuleMetadata.whenToApplyObj.record[condition.field]
                  ?.operatorsObj[condition.operator],
            }

            if (a.operator.name === 'SetValueFromRecord') {
              a.value = condition.valuesObj[condition.value]
            } else {
              a.value = condition.value
            }

            return a
          }
        )
      }

      this.isPrimary = this.rule.whenByRecord.isPrimary

      this.fieldsToRollUp = this.rule.fieldsToRollUp.map((field) => {
        return {
          field:
            this.rollUpRuleMetadata.rollUpFieldsPerEntityObject[
              this.finalRecordEntity
            ][field.name],
          operator:
            this.rollUpRuleMetadata.rollUpFieldsPerEntityObject[
              this.finalRecordEntity
            ][field.name]?.operatorsObj?.[field.operator],
          value: field.value,
          overridePrimary: field.overridePrimary,
        }
      })
    } else {
      this.finalRecordEntity = this.rollUpRuleMetadata.entities[0].name
      this.whenByColumn = this.rule.whenByColumn
      this.whenByRecord = this.rule.whenByRecord
      this.addFieldToRollUp()
    }
  },
  methods: {
    addWhenByColumn() {
      this.whenByColumn.push({
        field: null,
        operator: null,
        value: null,
      })
    },
    fieldChanged(prop, index) {
      Vue.set(prop, index, {
        ...prop[index],
        operator: null,
        value: null,
      })
    },
    operatorChanged(prop, index) {
      if (
        ['SetValue', 'SetValueFromField'].includes(prop[index].operator.name) &&
        this.whenByRecord.length === 0
      ) {
        this.addWhenByRecord()
      }

      Vue.set(prop, index, {
        ...prop[index],
        value: null,
      })
    },
    addWhenByRecord() {
      this.whenByRecord.push({
        field: null,
        operator: null,
        value: null,
      })
    },
    removeWhenByColumn(index) {
      const whenByColumn = this.whenByColumn
      whenByColumn.splice(index, 1)
      this.whenByColumn = whenByColumn
    },
    removeWhenByRecord(index) {
      const whenByRecord = this.whenByRecord
      whenByRecord.splice(index, 1)
      this.whenByRecord = whenByRecord
    },
    onSave() {
      if (this.$refs.form.validate()) {
        this.$emit('save', {
          finalRecordEntity: this.finalRecordEntity,
          lookUpEntities: this.lookUpEntities,
          whenByColumn: this.whenByColumn.map((el) => {
            return {
              field: el.field.fieldName,
              operator: el.operator.name,
              value: el.value,
            }
          }),
          whenByRecord: {
            isPrimary: this.isPrimary,
            conditions: this.whenByRecord.map((el) => {
              return {
                field: el.field.fieldName,
                operator: el.operator.name,
                value: el.value,
              }
            }),
          },
          fieldsToRollUp: this.fieldsToRollUp.map((el) => {
            return {
              name: el.field.name,
              operator: el.operator.name,
              value: el.value,
              overridePrimary: el.overridePrimary,
            }
          }),
        })
      }
    },
    addFieldToRollUp() {
      this.fieldsToRollUp = [
        ...this.fieldsToRollUp,
        {
          field: null,
          operator: null,
          value: null,
          overridePrimary: false,
        },
      ]
    },
    removeFieldToRollUp(index) {
      const fields = this.fieldsToRollUp
      fields.splice(index, 1)
      this.fieldsToRollUp = fields
    },
    resetFieldsToRollUp() {
      this.fieldsToRollUp = []
    },
  },
}
</script>

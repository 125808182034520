<template>
  <ApLoading v-if="isLoading" class="mt-10" text="Loading Roll Up Rule..." />

  <RulesStatusError v-else-if="isError" @click="getRollUpRule" />

  <div v-else class="pa-4 mx-auto" style="max-width: 600px">
    <div class="text-h5 font-weight-black mb-2">
      What specific values you want to keep?
    </div>

    <div class="text-body-2 mb-4">
      <v-icon small class="mr-1" color="primary">
        $mdi-information-outline
      </v-icon>
      The system will automatically keep the values from the Primary records
      unless specified differently. You can set up generic rules that are
      applied to all the entities or specific rules to each entity.
    </div>

    <RollUpRuleConditionsList
      v-if="rollUpRuleMetadata"
      :items="conditions"
      @edit="onEdit"
      @delete="onDelete"
    />

    <div class="d-flex justify-end">
      <v-btn
        text
        block
        outlined
        small
        class="text-none font-weight-regular"
        @click="onAddNew"
      >
        <v-icon left>$mdi-plus</v-icon>
        New Field
      </v-btn>
    </div>

    <RollUpForm
      v-if="isCriteriaDialogVisible"
      :rule="selectedCriteria"
      @save="onSave"
      @cancel="onCancel"
    />
  </div>
</template>

<script>
import ApLoading from '@/components/common/ApLoading'
import RulesStatusError from './RulesStatusError'
import RollUpForm from './RollUpRuleForm.vue'
import { mapActions, mapState } from 'vuex'
import { RULE_TYPES, entityColors } from '@/api/business-rules/rules'
import RollUpRuleConditionsList from './RollUpRuleConditionsList'
import { cloneDeep, isEqual } from 'lodash-es'
import Vue from 'vue'

export default {
  components: {
    ApLoading,
    RulesStatusError,
    RollUpRuleConditionsList,
    RollUpForm,
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      criteriaSelectedIndex: null,
      isCriteriaDialogVisible: false,
      conditions: [],
      originalConditions: null,
      entities: [],
    }
  },
  computed: {
    ...mapState('business-rules', {
      multiMapRule: (state) => state.directorRule.rules.MultiMap,
      rollUpRule: (state) => state.directorRule.rules.RollUp,
      rollUpRuleMetadata: (state) => state.rulesMetadata.RollUp,
      isTriggeringSave: (state) =>
        state.directorRule.rules.RollUp?.isTriggeringSave,
      directorRule: (state) => state.directorRule,
    }),
    selectedCriteria() {
      if (this.criteriaSelectedIndex === null) {
        return undefined
      }

      return this.conditions[this.criteriaSelectedIndex]
    },
  },
  watch: {
    async isTriggeringSave(value) {
      if (value) {
        try {
          if (!isEqual(this.originalConditions, this.conditions)) {
            if (this.rollUpRule.id) {
              await this.updateRollUpRule({
                rule: this.rollUpRule,
                conditions: this.conditions,
              })
            } else {
              await this.createRollUpRule({
                directorRuleId: this.$route.params.directorRuleId,
                rule: this.rollUpRule,
                conditions: this.conditions,
              })
            }
          }

          this.$emit('saved')
        } finally {
          this.resetTriggerSaveCurrentRule(this.rollUpRule.type)
        }
      }
    },
  },
  created() {
    this.getRollUpRule()
  },
  methods: {
    ...mapActions('business-rules', [
      'getRule',
      'getRuleEntityMetadata',
      'assignLocalRuleToDirector',
      'resetTriggerSaveCurrentRule',
      'updateRollUpRule',
      'createRollUpRule',
    ]),
    onAddNew() {
      this.criteriaSelectedIndex = null
      this.isCriteriaDialogVisible = true
    },
    onSave(rule) {
      const ruleWithEntityColor = {
        ...rule,
        finalRecordEntityColor: entityColors[rule.finalRecordEntity],
      }

      if (this.criteriaSelectedIndex === null) {
        this.conditions = [...this.conditions, ruleWithEntityColor]
      } else {
        Vue.set(
          this.conditions,
          this.criteriaSelectedIndex,
          ruleWithEntityColor
        )
      }

      this.isCriteriaDialogVisible = false
      this.criteriaSelectedIndex = null
    },
    onCancel() {
      this.isCriteriaDialogVisible = false
    },
    onEdit(criteriaIndex) {
      this.criteriaSelectedIndex = criteriaIndex
      this.isCriteriaDialogVisible = true
    },
    onDelete(index) {
      this.conditions.splice(index, 1)
    },
    async getRollUpRule() {
      this.isLoading = true
      this.isError = false

      try {
        if (this.directorRule.rules?.RollUp?.id) {
          await this.getRule(this.directorRule.rules.RollUp.id)
          this.conditions = cloneDeep(this.rollUpRule.conditions)
          this.originalConditions = cloneDeep(this.rollUpRule.conditions)
        } else {
          const payload = {
            id: null,
            name: `${this.directorRule.name}: RollUp Rule`,
            description: 'Manually created RollUp rule',
            entities: 'Account',
            type: RULE_TYPES.rollUp,
          }

          this.assignLocalRuleToDirector({ rule: payload })
        }

        this.entities = this.multiMapRule.entities

        const entities = this.entities.filter(
          (key) => !this.rollUpRuleMetadata?.[key]
        )

        if (entities.length > 0) {
          await this.getRuleEntityMetadata({
            entities: entities,
            ruleType: 'RollUp',
          })
        }
      } catch {
        this.isError = true
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

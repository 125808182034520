<template>
  <v-card outlined>
    <div class="d-flex align-center justify-space-between">
      <div class="d-flex align-center px-2 py-2">
        <v-icon class="handle mr-1"> $mdi-drag </v-icon>
        <div
          small
          class="font-weight-medium text-caption px-2 rounded-sm d-flex align-center ap-white--text"
          :class="item.finalRecordEntityColor"
        >
          {{ item.finalRecordEntity }}
        </div>
        <div class="text-caption px-3">
          <v-icon small>$mdi-text-search</v-icon>
          {{ item.lookUpEntities.join(', ') }}
        </div>
      </div>

      <div class="d-flex mr-2">
        <v-btn
          text
          small
          outlined
          class="text-none font-weight-regular"
          @click.stop="$emit('delete')"
        >
          <v-icon left> $mdi-delete-outline </v-icon>
          Delete
        </v-btn>
      </div>
    </div>

    <div class="ap-dark-gray--text px-4 pb-2 text-caption">
      <div class="font-weight-medium ap-black--text">Fields to Update</div>
      <span
        v-for="(field, fieldIndex) in item.fieldsToRollUp"
        :key="field.field"
      >
        {{
          rollUpRuleMetadata.rollUpFieldsPerEntityObject[
            item.finalRecordEntity
          ][field.name] &&
          rollUpRuleMetadata.rollUpFieldsPerEntityObject[
            item.finalRecordEntity
          ][field.name].label
        }}<span v-if="fieldIndex < item.fieldsToRollUp.length - 1">,</span>
      </span>
    </div>

    <div
      v-if="item.whenByColumn && item.whenByColumn.length > 0"
      class="ap-dark-gray--text px-4 pb-2 text-caption"
    >
      <div class="font-weight-medium ap-black--text">When some record</div>
      <span v-for="(when, whenIndex) in item.whenByColumn" :key="whenIndex">
        <template v-if="rollUpRuleMetadata.whenToApplyObj.column[when.field]">
          {{ rollUpRuleMetadata.whenToApplyObj.column[when.field].fieldLabel }}
          {{
            rollUpRuleMetadata.whenToApplyObj.column[when.field].operatorsObj[
              when.operator
            ].label
          }}
          {{ when.value }}
        </template>
      </span>
    </div>

    <div
      v-if="
        item.whenByRecord &&
        item.whenByRecord.conditions &&
        item.whenByRecord.conditions.length > 0
      "
      class="ap-dark-gray--text px-4 pb-2 text-caption"
    >
      <div class="font-weight-medium ap-black--text">
        <span v-if="item.whenByColumn && item.whenByColumn.length > 0">
          And
        </span>
        <span v-else>When</span> a record matches all following conditions:
      </div>
      <div v-if="item.whenByRecord.isPrimary">Is Primary record</div>
      <div
        v-for="(when, whenIndex) in item.whenByRecord.conditions"
        :key="whenIndex"
      >
        <template v-if="rollUpRuleMetadata.whenToApplyObj.record[when.field]">
          {{ rollUpRuleMetadata.whenToApplyObj.record[when.field].fieldLabel }}
          {{
            rollUpRuleMetadata.whenToApplyObj.record[when.field].operatorsObj[
              when.operator
            ].label
          }}
          {{ when.value }}
        </template>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('business-rules', {
      rollUpRuleMetadata: (state) => state.rulesMetadata.RollUp,
    }),
  },
}
</script>
